/** @jsx jsx */
import {jsx } from "theme-ui"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SiteLinks from "../components/site-links"
import { Separator } from "../components/gatsby-sidebar/docs-breadcrumb"
import CopyrightInfo from "../components/copyright-info"

const IndexPage = () => (
  <Layout>
    <SEO title="Site Home Page" />
    <div
      sx={{
        m: `0 auto`,
        maxWidth: 900,
      }}
    >
      <h1>Hi! I have ideas.</h1>

      <p>Sometimes I even have good ideas.</p>

      <blockquote
        sx={{ fontSize: `16px` }}
      >
        Yes, I am aware there are incomplete or inconsistent aspects to the site.  The site is a work in progress. It is a showcase, communication vehicle and meant for me to learn new technologies.
      </blockquote>


      <p>Each time you visit, there may be <b>material</b> changes (<i>hopefully for the better</i>).  So do come back.</p>

      <p>This site contains:</p>
      <ul>
        <li>A shell for a blog</li>
        <li>Project showcase/links</li>
        <li>Testing of new ideas - such as recreating most of the Docs page on gatsbyjs.org.</li>
      </ul>

      <p>
        I am available for employment as a programmer, web developer, or database designer/maintainer. Remote work preferred.
        <br />
        My compensation expectations are commensurate with my experience in this new profession, not where I left off in my previous career.
        <br /><br />
        Please contact me at <a href="mailto:danhamilton1964@gmail.com">Dan J. Hamilton</a>
      </p>
    </div>

    <SiteLinks>
      <Link to="/index-blog/">Blog</Link>
      <Separator character={<span>{` `}</span>} />
      <Link to="/index-project/">Projects</Link>
    </SiteLinks>
    
    <CopyrightInfo />
  </Layout>
)

export default IndexPage
